import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--multiselect"
export default class extends Controller {
  static values = {
    placeholder: String,
    selectedOptions: String,
    unselectAll: String,
    selectAll: String,
    showCheckbox: { type: Boolean, default: true },
    singleSelect: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
  };

  connect() {
    const texts = {
      placeholder: this.placeholderValue,
      selectedOptions: this.selectedOptionsValue,
    };
    if (this.unselectAllValue) {
      texts.unselectAll = this.unselectAllValue;
    }
    if (this.selectAllValue) {
      texts.selectAll = this.selectAllValue;
    }

    $(this.element).multiselect({
      texts,
      selectAll: !!this.selectAllValue,
      search: this.searchValue,
      showCheckbox: this.showCheckboxValue,
      onOptionClick: (_select, option) => {
        this.dispatch("option-clicked");

        if (this.singleSelectValue) {
          const $option = $(option);
          const $sort = $(this.element);
          $option.closest("ul").find("li").removeClass("selected");
          $sort.find("option").prop("selected", false);

          $option.closest("li").addClass("selected");
          $sort
            .find('option[value="' + $option.val() + '"]')
            .prop("selected", true);
        }
      },
    });
  }
}
