import React from 'react'

const DeliverableTypeDropdowns = ({
  deliverableCategories,
  deliverableTypesByCategory,
  nameScope,
  deliverableCategory,
  deliverableType,
  showErrors
}) => {
  const [selectedCategory, setSelectedCategory] = React.useState(deliverableCategory)
  const [selectedType, setSelectedType] = React.useState(deliverableType)

  const handleCategoryChange = event => {
    setSelectedCategory(event.target.value)
  }

  const handleTypeChange = event => {
    setSelectedType(event.target.value)
  }

  return (
    <>
      <div className="Form__labeledInput">
        <div className="Form__label">
          <h1> Deliverable Category </h1>
          <p> Required </p>
        </div>
        <div className="Form__input">
          <select
            name={`${nameScope}[deliverable_category]`}
            onChange={handleCategoryChange}
            defaultValue={deliverableCategory}
          >
            <option value=''> Select category </option>
            {
              Object.entries(deliverableCategories).map(
                ([key, val]) => <option key={key} value={key}> {val} </option>
              )
            }
          </select>
          { showErrors && !selectedCategory &&
            <small className="field_with_errors"> Required </small>
          }
        </div>
      </div>

      <div className="Form__labeledInput">
        <div className="Form__label">
          <h1> Type </h1>
          <p> Required </p>
        </div>

        <div className="Form__input">
          <select
            name={`${nameScope}[deliverable_type]`}
            onChange={handleTypeChange}
            defaultValue={deliverableType}
          >
            <option value=''> Select type </option>
            { selectedCategory &&
              Object.entries(deliverableTypesByCategory[selectedCategory]).map(
                ([key, val]) => <option key={key} value={key}> {val} </option>
              )
            }
          </select>
          { showErrors && !selectedType &&
            <small className="field_with_errors"> Required </small>
          }
        </div>
      </div>
    </>
  )
}

export default DeliverableTypeDropdowns
