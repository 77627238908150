import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

const NEW_USER_CSS_CLASS = "AssetGroup__newUserRow";
const DELETED_USER_CSS_CLASS = "AssetGroup__deletedUserRow";

// Connects to data-controller="asset-library--asset-groups--form"
export default class extends Controller {
  static targets = [
    "mainDetails",
    "mainInputs",
    "input",
    "submit",
    "searchCount",
  ];

  connect() {
    this.membersChanged = false;
    this.inputTargets.forEach((input) => {
      input.dataset.initialValue = input.value;
      input.addEventListener(
        "input",
        debounce(this.#checkFormChanged.bind(this), 100)
      );
    });
  }

  updateSearchCount(event) {
    const { rows } = event.detail;
    this.searchCountTarget.textContent = rows.length;
  }

  removeUser(event) {
    const user = {
      cssClass: DELETED_USER_CSS_CLASS,
      id: parseInt(event.params.id, 10),
    };
    this.dispatch("unselected", { detail: { data: user } });
    this.#membersChanged();
  }

  cancelDetails() {
    this.mainDetailsTarget.hidden = false;
    this.mainInputsTarget.hidden = true;
    setTimeout(this.#checkFormChanged.bind(this), 0);
  }

  editDetails() {
    this.mainDetailsTarget.hidden = true;
    this.mainInputsTarget.hidden = false;
  }

  addItem(event) {
    const { data } = event.detail;
    (Array.isArray(data) ? data : [data]).forEach((item) => {
      const dataset = item.element.dataset;
      const deleteLink = new DOMParser().parseFromString(
        dataset.deleteLink,
        "text/html"
      ).documentElement.textContent;
      const user = {
        company_title: dataset.companyTitle,
        cssClass: NEW_USER_CSS_CLASS,
        delete_link: deleteLink,
        email: dataset.email,
        first_name: dataset.firstName,
        id: dataset.id,
        last_name: dataset.lastName,
      };
      this.dispatch("add-user", { detail: { data: user } });
      this.#membersChanged();
    });
  }

  #membersChanged() {
    this.membersChanged = true;
    this.#checkFormChanged();
  }

  #checkFormChanged() {
    const inputsChanged = this.inputTargets.some(
      (input) => input.value !== input.dataset.initialValue
    );
    const changed = this.membersChanged || inputsChanged;

    this.submitTargets.forEach((submit) => {
      submit.disabled = !changed;
    });
  }
}
