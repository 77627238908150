import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

// Connects to data-controller="shared--select-child-options"

export default class extends Controller {
  static targets = ["parentSelect", "childSelect"]

  connect() {
    this.$parentSelect = $(this.parentSelectTarget);
    this.$childSelect = $(this.childSelectTarget);

    if (!this.childSelectTarget.value) {
      this.childSelectTarget.disabled = true
    }

    this.#initializeSelect2();
    this.#addEventListeners();
  }

  #initializeSelect2() {
    const options = {
      placeholder: "Select or create new",
      tags: true,
      width: "100%",
      createTag: function (params) {
        return {
          id: params.term,
          text: params.term,
          newOption: true,
        };
      },
      templateResult: function (data) {
        var $result = $('<span></span>');
        $result.text(data.text);
        if (data.newOption) {
          $result.prepend('Add new: </em>');
        }
        return $result;
      },
    };

    this.$parentSelect.select2(options);
    this.$childSelect.select2(options);
  }

  #addEventListeners() {
    this.$parentSelect.on("select2:select", this.#handleSelect.bind(this));
  }

  #handleSelect(event) {
    let url = this.parentSelectTarget.dataset.url
    let name = this.parentSelectTarget.value
    
    this.#loadOptions(url, name)
  }

  async #loadOptions(url, name) {
    const request = new FetchRequest(
      'GET',
      url,
      {
        responseKind: "html",
        query: { name },
      }
    )
    const response = await request.perform()

    if (response.ok) {
      const optionsHtml = await response.html
      this.#populateChildSelect(optionsHtml)
    }
  }

  #populateChildSelect(optionsHtml) {
    this.childSelectTarget.innerHTML = optionsHtml
    this.childSelectTarget.disabled = false
  }
}