import { Controller } from "@hotwired/stimulus";
import WebViewer from "@pdftron/webviewer";
import { initializeVideoViewer } from "@pdftron/webviewer-video";
import { initializeAudioViewer } from "@pdftron/webviewer-audio";
import {
  generateButton,
  getImageBlobs,
  loadAnnotations,
  saveModal,
  sendMarkupSession,
} from "../../components/kronickle/markup_utils";

const WEBVIEWER_PATH = "/webviewer-10.6.0";

// Connects to data-controller="shared--markup-viewer"
export default class extends Controller {
  static values = {
    annotationsUrl: String,
    backUrl: String,
    cssUrl: String,
    currentUserName: String,
    kind: String,
    licenseKey: String,
    markupSessionsUrl: String,
    readOnly: { type: Boolean, default: false },
    url: String,
  };

  connect() {
    this.#handleAnnotationSelected = this.#handleAnnotationSelected.bind(this);
    this.options = {
      licenseKey: this.licenseKeyValue,
      annotationUser: this.currentUserNameValue,
      css: this.cssUrlValue,
      path: WEBVIEWER_PATH,
      loadAsPDF: true,
      selectAnnotationOnCreation: true,
      enableAnnotationNumbering: true,
      disabledElements: [
        "addReplyAttachmentButton",
        "annotationGroupButton",
        "annotationUngroupButton",
        "arcToolGroupButton",
        "multiGroupButton",
        "multiStateButton",
        "notePopupStateCancelled",
        "notePopupStateCompleted",
        "notePopupStateMarked",
        "notePopupStateRejected",
        "notePopupStateUnmarked",
        "polygonCloudToolGroupButton",
        "settingsButton",
        "thumbnailControl",
        "toolbarGroup-Edit",
        "toolbarGroup-FillAndSign",
        "toolbarGroup-Forms",
        "toolbarGroup-Insert",
        "viewControlsButton",
        "markInsertTextGroupButton",
        "markReplaceTextGroupButton",
      ],
    };
    if (this.readOnlyValue) {
      this.options.hideAnnotationPanel = true;
      this.options.disabledElements.push("header");
    }
    this.#init();
  }

  disconnect() {
    const {
      Core: { annotationManager },
    } = this.instance;

    if (this.instance) {
      annotationManager.removeEventListener(
        "annotationSelected",
        this.#handleAnnotationSelected,
      );
      annotationManager.removeEventListener("addReply", this.#handleAddReply);
      if (this.audioInstance) {
        this.audioInstance = undefined;
      }
    }
  }

  selectAnnotation({ detail }) {
    if (!this.instance) {
      return;
    }

    const {
      Core: { documentViewer, annotationManager },
    } = this.instance;
    const annotation = annotationManager.getAnnotationById(detail.annotationId);

    if (!annotation) {
      return;
    }

    annotationManager.removeEventListener(
      "annotationSelected",
      this.#handleAnnotationSelected,
    );
    annotationManager.selectAnnotation(annotation);

    if (this.#isVideo()) {
      const video = documentViewer.getDocument().getVideo();
      video.updateAnnotationsToTime(annotation.getStartTime());
      video.goToTime(annotation.getStartTime());
    } else if (this.#isAudio()) {
      const waveSurfer = this.audioInstance.getWaveSurfer();
      const totalDuration = waveSurfer.getDuration();
      const secondsToSeekTo = annotation.getStartTime();
      const seekToPercentage = secondsToSeekTo / totalDuration;
      waveSurfer.seekTo(seekToPercentage);
    }

    annotationManager.addEventListener(
      "annotationSelected",
      this.#handleAnnotationSelected,
    );
  }

  // Private

  async #init() {
    this.instance = await WebViewer(this.options, this.element);
    this.#setupInstance();
  }

  #setupInstance() {
    this.#setupDocumentViewer();
    this.#setupUI();
    this.#setupAnnotationManager();
  }

  #setupDocumentViewer() {
    const {
      Core: { documentViewer, annotationManager },
      UI,
    } = this.instance;

    if (this.#isVideo()) {
      documentViewer.addEventListener(
        "videoElementReady",
        async () => {
          const video = documentViewer.getDocument().getVideo();
          const annotations = await loadAnnotations(this.annotationsUrlValue);

          await Promise.all(
            annotations.map((a) =>
              annotationManager.importAnnotationCommand(a),
            ),
          );

          video.updateAnnotationsToTime(0);
        },
        { once: true },
      );

      // FIXME: This is a hack to get the video to load properly on big retina screens
      const iframeDoc = UI.iframeWindow.document;
      const documentContainer = iframeDoc.querySelector(".DocumentContainer");
      documentContainer.style.maxWidth = "1400px";
      setTimeout(() => (documentContainer.style.maxWidth = "none"), 1000);
      // End hack
    } else if (this.#isAudio()) {
      documentViewer.addEventListener("audioViewerReady", async () => {
        const annotations = await loadAnnotations(this.annotationsUrlValue);

        await Promise.all(
          annotations.map((a) => annotationManager.importAnnotationCommand(a)),
        );
      });

      documentViewer.addEventListener(
        "documentLoaded",
        async () => {
          const annotations = await loadAnnotations(this.annotationsUrlValue);
          Promise.all(
            annotations.map((a) =>
              annotationManager.importAnnotationCommand(a),
            ),
          );
        },
        { once: true },
      );
    } else {
      documentViewer.setDocumentXFDFRetriever(async () => {
        return await loadAnnotations(this.annotationsUrlValue);
      });
    }
  }

  async #setupUI() {
    const {
      Core: { documentViewer },
      UI,
    } = this.instance;

    UI.setTheme("light");
    UI.setTimezone("America/Los_Angeles");

    UI.setTranslations("en", {
      "option.state.accepted": "Resolved",
      "option.state.none": "Unresolved",
    });

    UI.disableFeatures([
      UI.Feature.ThumbnailMerging,
      UI.Feature.ThumbnailReordering,
      UI.Feature.LocalStorage,
    ]);

    if (this.readOnlyValue) {
      UI.disableReplyForAnnotations((_annotation) => true);
    } else {
      UI.addCustomModal(saveModal);
      UI.setHeaderItems((header) => {
        header.push(this.#generateSaveMarkupButton());
        header.push(this.#generateCancelButton());
      });
    }

    if (this.#isMedia()) {
      this.audioInstance = await initializeAudioViewer(this.instance, {
        license: this.licenseKeyValue,
      });

      if (this.#isAudio()) {
        this.audioInstance.loadAudio(this.urlValue);
      } else if (this.#isVideo()) {
        initializeVideoViewer(this.instance, {
          license: this.licenseKeyValue,
          AudioComponent: this.audioInstance.Waveform,
          cacheAudioWaveForm: false,
          defaultLoadAudio: false,
          showFrames: false,
        }).then(({ loadVideo }) => loadVideo(this.urlValue));
      }
    } else {
      UI.loadDocument(this.urlValue);
    }

    documentViewer.addEventListener(
      "documentLoaded",
      () => {
        UI.openElements(["notesPanel"]);
      },
      { once: true },
    );
  }

  #setupAnnotationManager() {
    const {
      Core: { annotationManager },
    } = this.instance;

    if (this.readOnlyValue) {
      annotationManager.enableReadOnlyMode();
    } else {
      annotationManager.addEventListener("addReply", this.#handleAddReply);
    }

    annotationManager.addEventListener(
      "annotationSelected",
      this.#handleAnnotationSelected,
    );
  }

  #handleAddReply = (reply, _parent, _root) => {
    if (
      reply.Subject === "Sticky Note" &&
      reply.Hidden &&
      reply.StateModel === "Review"
    ) {
      const status = reply.State === "Accepted" ? "Resolved" : "Unresolved";
      reply.setContents(`Status updated to ${status}`);
    }
  };

  #handleAnnotationSelected = (annotations, action) => {
    if (action === "selected" && annotations.length > 0) {
      this.dispatch("annotation-selected", {
        detail: { annotationId: annotations[0].Id },
      });
    } else if (action === "deselected") {
      console.log("annotation deselection");
    }
  };

  #generateSaveMarkupButton() {
    return {
      type: "customElement",
      render: () => {
        const button = generateButton({
          label: "Save Markups",
          classNames: "primary",
        });
        button.onclick = this.#handleSaveMarkupClick.bind(this);

        return button;
      },
    };
  }

  #generateCancelButton() {
    if (!this.backUrlValue) {
      return;
    }

    return {
      type: "customElement",
      render: () => {
        const button = generateButton({
          label: "Cancel",
          classNames: "secondary",
        });
        button.onclick = () => {
          location.href = this.backUrlValue;
        };

        return button;
      },
    };
  }

  #handleSaveMarkupClick = async () => {
    const {
      Core: { documentViewer, annotationManager },
      UI,
    } = this.instance;

    UI.openElements([saveModal.dataElement]);

    const xfdfString = await annotationManager.exportAnnotationCommand();
    let imageBlobs = {};

    if (!this.#isMedia()) {
      imageBlobs = await getImageBlobs(xfdfString, documentViewer, annotationManager);
    }

    const response = await sendMarkupSession(
      xfdfString,
      this.markupSessionsUrlValue,
      imageBlobs,
    );
    const data = await response.json();
    location.href = data.redirect_url;
  };

  #isVideo() {
    return this.kindValue === "video";
  }

  #isAudio() {
    return this.kindValue === "audio";
  }

  #isMedia() {
    return this.#isVideo() || this.#isAudio();
  }
}
