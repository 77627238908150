import React, { useState } from 'react'

const SubmissionsCollectionExpiration = (props) => {
  const [daysToExpiration, setDaysToExpiration] = useState(props.daysToExpiration)
  const [isLoading, setIsLoading] = useState(false)

  const expired = daysToExpiration <= 0

  const updateUrl = (action) => {
    setIsLoading(true)
    $.post(props.updateUrl, { _method: 'patch', url: { action }})
      .done((response) => {
        setDaysToExpiration(response.url.days_to_expiration)
        setIsLoading(false)
      })
      .fail(() => alert("Oops! Something went wrong, please reload the page and try again..."))
  }

  return (
    <div className="AmgCollectionLinks__actions">
      <button
        type="button"
        className="AmgCollectionLinks__button AmgCollectionLinks__renewButton"
        onClick={ () => updateUrl('renew') }
        disabled={isLoading}
      >
        <i className="fa fa-repeat" />
        Renew Link for 7 days
      </button>

      <span className="AmgCollectionLinks__notice">
        { expired && "Expired" }
        { !expired && `${daysToExpiration} days left` }
      </span>


      { !expired &&
        <button
          type="button"
          className="AmgCollectionLinks__button AmgCollectionLinks__expireButton"
          onClick={() => updateUrl('expire')}
          disabled={isLoading}
        >
          <i className="fa fa-times" />
          Expire Now
        </button>
      }
    </div>
  )
}

export default SubmissionsCollectionExpiration
