import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--toggle-show-hide"
export default class extends Controller {
  static values = {
    initialVisible: { type: Boolean, default: true },
  };
  static targets = ["element"];

  connect() {
    this.visible = this.initialVisibleValue;
    this.elementTargets.forEach((element) => {
      element.hidden = !this.visible;
    });
  }

  toggle() {
    this.visible = !this.visible;
    this.elementTargets.forEach((element) => {
      element.hidden = !this.visible;
    });
  }
}
