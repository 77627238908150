/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import DeliverableTypeDropdowns from "components/deliverable_type_dropdowns";
import SubmissionsCollectionExpiration from 'components/submissionsCollectionExpiration';
import "controllers";
import "@hotwired/turbo-rails";

Turbo.session.drive = false

Turbo.setConfirmMethod((message, element) => {
  let $dialog = $('#turbo-confirm');
  $dialog.find('p').html(message);

  let modal = new APP.Modal($dialog).open();

  return new Promise((resolve, reject) => {
    $dialog.find('.ConfirmationDialog__confirm a').on('click', function(e) {
      e.preventDefault();
      modal.close();
      resolve(true)
    });
  })
})

document.addEventListener('turbo:load', () => {
   let nodes = document.getElementsByClassName('js-submissions-collection-expiration');
   nodes = [...nodes]
   nodes.forEach(node => {
      const data = JSON.parse(node.getAttribute('data'));
      ReactDOM.render(<SubmissionsCollectionExpiration {...data} />, node);
   })
});

window.ReactDOM = ReactDOM;
window.React = React;

window.components = {
  DeliverableTypeDropdowns,
};
