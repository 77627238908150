import { Controller } from "@hotwired/stimulus";
import Split from "split.js";

// Connects to data-controller="shared--split-panels"
export default class extends Controller {
  static targets = ["panel"];
  static values = {
    minSize: { type: Number, default: 450 },
    gutterSize: { type: Number, default: 12 },
    sizes: { type: Array, default: [50, 50] },
  };

  connect() {
    const options = {
      minSize: this.minSizeValue,
      gutterSize: this.gutterSizeValue,
      sizes: this.sizesValue,
    };
    this.split = Split(this.panelTargets, options);
  }

  disconnect() {
    this.split.destroy();
  }
}
