import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--select-two"
export default class extends Controller {
  static values = {
    data: Array,
    enableSelectAll: Boolean,
    fieldsToFilter: { type: Array, default: [] },
    placeholder: String,
    preventUnselecting: { type: Boolean, default: false },
    url: String,
  };

  connect() {
    this.$select = $(this.element);

    this.#initializeSelect2();
    this.#addEventListeners();
  }

  unselect(event) {
    const { data } = event.detail;
    const itemId = data.id;
    const currentData = this.$select.select2("data");
    const newData = currentData.filter((item) => {
      if (item.id.toString() !== itemId.toString()) {
        return item;
      }
    });
    this.$select.val(newData.map((item) => item.id));
    this.$select.trigger("change");
  }

  disconnect() {
    this.$select.select2("destroy");
  }

  #initializeSelect2() {
    const { placeholderValue, dataValue, urlValue, enableSelectAllValue } = this;
    const options = {
      placeholder: placeholderValue,
      data: dataValue,
      escapeMarkup: (markup) => markup,
      templateResult,
      matcher: matcher.bind(this),
    };

    if (enableSelectAllValue) {
      options.sorter = addSelectAll;
    }

    if (urlValue) {
      options.ajax = {
        url: urlValue,
        dataType: "json",
        minimumInputLength: 2,
        processResults: (data) => ({
          results: data,
          pagination: { more: false },
        }),
      };
    }
    this.$select.select2(options);
  }

  #addEventListeners() {
    const { preventUnselectingValue } = this;

    this.$select.on("select2:select", this.#handleSelect.bind(this));
    if (preventUnselectingValue) {
      this.$select.on("select2:unselecting", (event) => {
        event.preventDefault();
      });
    }
    this.$select.on("select2:unselect", (event) => {
      this.dispatch("unselected", { detail: { data: event.params.data } });
    });
  }

  #handleSelect(event) {
    if (event.params.data.id === "selectAll") {
      const currentSelectionIds = this.$select.val() || [];
      this.$select.val([
        ...event.params.data.matchItems.map((item) => item.id),
        ...currentSelectionIds.filter((id) => id !== "selectAll"),
      ]);
      this.$select.trigger("change");
      this.$select.trigger({
        type: "select2:select",
        params: { data: event.params.data.matchItems },
      });
    } else {
      this.dispatch("selected", { detail: { data: event.params.data } });
    }
  }
}

const addSelectAll = (matches) => {
  const unselectedMatches = matches.filter(
    (i) => !i.element || !i.element.selected
  );
  if (unselectedMatches.length > 0) {
    return [
      {
        id: "selectAll",
        text: `Select All (${unselectedMatches.length} matches)`,
        matchItems: matches.filter((i) => i.id !== "selectAll"),
      },
      ...matches.filter((i) => i.id !== "selectAll"),
    ];
  }

  return [];
};

const templateResult = (item) => {
  if (item.element && item.element.selected) {
    return null;
  }
  return item.text;
};

function matcher(params, data) {
  if ((params.term || "").trim() === "") {
    return data;
  }

  if (!data.text) {
    return null;
  }

  if (data.element.selected) {
    return null;
  }

  const dataset = data.element.dataset;

  if (this.fieldsToFilterValue.length > 0) {
    const anyMatch = this.fieldsToFilterValue.some((field) => {
      const camelField = snakeToCamel(field)
      if (!dataset[camelField]) return;

      const value = dataset[camelField].toString().toLowerCase();
      if (value.indexOf(params.term.toLowerCase()) > -1) {
        return data;
      }
    });

    return anyMatch ? data : null;
  } else if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
    return data;
  } else {
    return null;
  }
}

function snakeToCamel(str){
  return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}
