import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';

// Connects to data-controller="shared--swiper-carousel"
export default class extends Controller {
  static values = {
    spaceBetween: { type: Number, default: 20 },
    slidesPerView: { type: String, default: 'auto' },
    activeSlideClass: { type: String, default: '.js-swiper-active-slide' },
    nextEl: { type: String, default: '.js-swiper-next' },
    prevEl: { type: String, default: '.js-swiper-prev' },
  };

  connect() {
    const thumbnailsSwiper = new Swiper(this.element, {
      centeredSlides: true,
      grabCursor: true,
      spaceBetween: this.spaceBetweenValue,
      slidesPerView: this.slidesPerViewValue,
      navigation: {
        nextEl: this.nextElValue,
        prevEl: this.prevElValue,
     },
      on: {
        click: (swiper) => {
          const slides = swiper.slides;

          slides.forEach(slide => {
            slide.classList.remove(this.activeSlideClassValue);
          });

          swiper.clickedSlide.classList.add(this.activeSlideClassValue);
          swiper.slideTo(swiper.clickedIndex);
        },
     },
    });
  }
}
