import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--collapse-expand"
export default class extends Controller {
  static targets = ["trigger", "content"];
  static values = {
    collapsed: { type: Boolean, default: false },
    collapsedClass: { type: String, default: "collapsed" },
    contentsClass: { type: String, default: "CollapseExpand__content" },
    mainClass: { type: String, default: "CollapseExpand" },
    triggersClass: { type: String, default: "CollapseExpand__trigger" },
  };

  connect() {
    this.element.classList.add(this.mainClassValue);
    this.triggerTargets.forEach((triggerElement) =>
      triggerElement.classList.add(this.triggersClassValue),
    );
    this.contentTargets.forEach((contentElement) =>
      contentElement.classList.add(this.contentsClassValue),
    );
    this.#applyState();
  }

  expand(event) {
    if (!this.#isTrigger(event)) {
      return;
    }

    this.collapsedValue = false;
    this.#applyState();
  }

  collapse(event) {
    if (!this.#isTrigger(event)) {
      return;
    }

    this.collapsedValue = true;
    this.#applyState();
  }

  toggle(event) {
    if (!this.#isTrigger(event)) {
      return;
    }

    this.collapsedValue = !this.collapsedValue;
    this.#applyState();
  }

  #applyState() {
    const isCollapsed = this.collapsedValue;

    this.triggerTargets.forEach((triggerElement) => {
      isCollapsed
        ? triggerElement.classList.add(this.collapsedClassValue)
        : triggerElement.classList.remove(this.collapsedClassValue);
    });
    this.contentTargets.forEach((contentElement) => {
      isCollapsed
        ? contentElement.classList.add(this.collapsedClassValue)
        : contentElement.classList.remove(this.collapsedClassValue);
    });
  }

  #isTrigger(event) {
    return this.triggerTargets.indexOf(event.target) >= 0;
  }
}
