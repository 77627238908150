import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dept-amg--programmer-reviews--submission-feedback"
export default class extends Controller {
  static targets = ['comments', 'error'];
  static values = {
    url: String,
  }

  initialize() {
    this.formData = new FormData();
  };

  decline() {
    if (!this.commentsTarget.value) {
      this.errorTarget.style.display = 'block';
    } else {
      this.formData.append('submission[requested_changes]', this.commentsTarget.value);
      this.formData.append('submission[approved]', false);
      submitFeedback(this.urlValue, this.formData);
    }

  }

  changesRequired() {
    if (!this.commentsTarget.value) {
      this.errorTarget.style.display = 'block';
    } else {
      this.formData.append('submission[requested_changes]', this.commentsTarget.value);
      this.formData.append('submission[approved]', '');
      submitFeedback(this.urlValue, this.formData);
    }
  }

  approveWithChanges() {
    if (!this.commentsTarget.value) {
      this.errorTarget.style.display = 'block';
    } else {
      this.formData.append('submission[requested_changes]', this.commentsTarget.value);
      this.formData.append('submission[approved]', true);
      submitFeedback(this.urlValue, this.formData);
    }
  }

  approve() {
    if (this.commentsTarget.value) {
      this.formData.append('submission[additional_comments]', this.commentsTarget.value);
    }

    this.formData.append('submission[approved]', true);
    submitFeedback(this.urlValue, this.formData);
  }

}

const submitFeedback = (url, formData) => {
  const token = document.querySelector('meta[name="csrf-token"]').content;

  fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'X-CSRF-Token': token,
    },
    credentials: 'same-origin',
  }).then(response => {
    location.href = location.href;
  });
}
